<script>
import { Pie } from "vue-chartjs";

export default {
  extends: Pie,
  mounted() {
    this.gradient = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);
    this.gradient2 = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);

    this.gradient.addColorStop(0, "rgba(255, 0,0, 0.5)");
    this.gradient.addColorStop(0.5, "rgba(255, 0, 0, 0.25)");
    this.gradient.addColorStop(1, "rgba(255, 0, 0, 0)");

    this.gradient2.addColorStop(0, "rgba(0, 231, 255, 0.9)");
    this.gradient2.addColorStop(0.5, "rgba(0, 231, 255, 0.25)");
    this.gradient2.addColorStop(1, "rgba(0, 231, 255, 0)");
    this.renderChart(
      {
        labels: ["USDC", "BUSD", "UST", "USDT"],
        datasets: [
          {
            backgroundColor: [
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 205, 86, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              
            ],
            borderColor: [
              'rgb(54, 162, 235)',
              'rgb(255, 205, 86)',
              'rgb(153, 102, 255)',
              'rgb(75, 192, 192)',
            ],
            data: [59, 29, 0, 12]
          }
        ]
      },
      { 
        "legend": {
          "display": true,
          // "labels": {
          //     "fontSize": 20,
          // },
          "onClick": null,
        },
        responsive: true, 
        maintainAspectRatio: false,
        // title: {
        //   display: true,
        //   text: '歷史利率',
        //   fontSize: 14,
        //   fontColor: "#AAAAAA",
        // },
      }
    );
  }
};
</script>